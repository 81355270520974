export enum VacancyFormMode {
  Create = 'create',
  CreateEdit = 'create-edit',
  CreatePreview = 'create-preview',
  Duplicate = 'duplicate',
  StandaloneEdit = 'standalone-edit',
  StandalonePreview = 'standalone-preview',
}

export const isEditMode = (
  mode: VacancyFormMode
): mode is VacancyFormMode.CreateEdit | VacancyFormMode.StandaloneEdit =>
  [VacancyFormMode.CreateEdit, VacancyFormMode.StandaloneEdit].includes(mode);

export function isEditOrDuplicateMode(
  mode: VacancyFormMode
): mode is VacancyFormMode.CreateEdit | VacancyFormMode.StandaloneEdit | VacancyFormMode.Duplicate {
  return isEditMode(mode) || mode === VacancyFormMode.Duplicate;
}

export function isInCreationProcess(
  mode: VacancyFormMode
): mode is
  | VacancyFormMode.Create
  | VacancyFormMode.Duplicate
  | VacancyFormMode.CreateEdit
  | VacancyFormMode.CreatePreview {
  return [
    VacancyFormMode.Create,
    VacancyFormMode.Duplicate,
    VacancyFormMode.CreateEdit,
    VacancyFormMode.CreatePreview,
  ].includes(mode);
}
