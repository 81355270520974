import {
  ActiveTab,
  ProductCode,
  ProductOfferingUpgrade,
  ProductPlatformGroup,
} from '@mkp/shared/util-product';
import { createActionGroup, props } from '@ngrx/store';

export const productOptionsPageActions = createActionGroup({
  source: 'Product Options Page',
  events: {
    'Select Product': props<{ productCode: ProductCode; voucher?: string }>(),
    // weird that we have two "enter" actions here
    enter: props<{ platformGroup: ProductPlatformGroup }>(),
    'Enter Page': props<{ productCode: ProductCode; voucher: string }>(),
    'Load Product List': props<{ platformGroup: ProductPlatformGroup }>(),
    'Switch Platform Group': props<{ platformGroup: ProductPlatformGroup | null }>(),
    'Set Upgrade': props<ProductOfferingUpgrade>(),
    'Scroll Products': props<{ direction: 'left' | 'right' }>(),
    'Remove Products Cms Page': props<{ productCodes: string[] }>(),
    'Set Active Tab': props<{ activeTab: ActiveTab }>(),
    'Set Product Price Discount': props<{ productsDiscount: Record<string, number> | null }>(),
  },
});
