import { Injectable } from '@angular/core';
import { gdprModalActions, gdprTealiumActions } from '@mkp/gdpr/state/actions';
import { objectEntries } from '@mkp/shared/util-format';
import {
  TealiumConsent,
  TealiumConsentCategory,
  TealiumService,
} from '@mkp/tracking/feature-tealium';
import { TrackingActions } from '@mkp/tracking/state/actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import {
  Gdpr,
  TealiumCategoryFiltered,
  TealiumConsentDto,
  TealiumConsentFiltered,
} from './gdpr.model';

type AppCategoriesMap = { [key in TealiumCategoryFiltered]: keyof Gdpr };
const APP_CATEGORIES_MAP: AppCategoriesMap = {
  analytics: 'functional',
  search: 'marketing',
};

type TealiumCategoriesMap = { [key in keyof Gdpr]: TealiumCategoryFiltered };
const TEALIUM_CATEGORIES_MAP: TealiumCategoriesMap = {
  functional: 'analytics',
  marketing: 'search',
};

@Injectable()
export class GdprEffects {
  getGdpr$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrackingActions.loadTealiumSuccess, gdprTealiumActions.setGdprToTealiumSuccess),
      switchMap(() => this.tealiumService.getConsentState()),
      map((consentState) => mapToGdpr(consentState)),
      map((gdpr) => gdprTealiumActions.loadGdprFromTealiumSuccess({ gdpr })),
      catchError((error) => {
        console.error(error);
        return of(
          gdprTealiumActions.loadGdprFromTealiumFailure({
            error: { message: error?.message ?? error.toString() },
          })
        );
      })
    )
  );

  setGdpr$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        gdprModalActions.initGdprFromBanner,
        gdprModalActions.initGdprFromModal,
        gdprModalActions.setGdprFromUserFooter,
        gdprModalActions.setGdprFromVisitorFooter,
        gdprModalActions.setGdprFromZendeskPlaceholder
      ),
      map(({ gdpr }) => mapToConsentDto(gdpr)),
      switchMap((consentState) => this.tealiumService.setConsentState(consentState)),
      map(() => gdprTealiumActions.setGdprToTealiumSuccess()),
      catchError((error) => {
        console.error(error);
        return of(
          gdprTealiumActions.setGdprToTealiumFailure({
            error: { message: error?.message ?? error.toString() },
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private readonly tealiumService: TealiumService
  ) {}
}

// null means there was an error initiating tealium
// 1 means all cookies are accepted by user
// 0 means the cookie was never set by user
// -1 means all cookies were refused by user
// array: partial cookie acceptance
const mapToGdpr = (consentState: [TealiumConsent] | null | -1 | 0 | 1): Gdpr =>
  consentState === 1
    ? getFullConsent()
    : consentState === 0 || consentState == null
      ? getEmptyConsent()
      : consentState === -1
        ? getRefusedConsent()
        : mapConsents(consentState);
const getFullConsent = (): Gdpr => ({ functional: true, marketing: true });
const getRefusedConsent = (): Gdpr => ({ functional: false, marketing: false });
const getEmptyConsent = (): Gdpr => ({ functional: null, marketing: null });

// transform partial tealium consent into a Gdpr object
// 1 - keep only the consents that we use in the app
// 2 - transform the array into a Gdpr object
const mapConsents = (categories: [TealiumConsent]): Gdpr =>
  categories
    .filter(isTealiumConsent)
    .reduce(
      (acc, { ct, name }) => ({ ...acc, [APP_CATEGORIES_MAP[name]]: ct === '1' }),
      {} as Gdpr
    );

// typeguards
const isTealiumCategory = (category: TealiumConsentCategory): category is TealiumCategoryFiltered =>
  APP_CATEGORIES_MAP[category as TealiumCategoryFiltered] != null;
const isTealiumConsent = (consent: TealiumConsent): consent is TealiumConsentFiltered =>
  isTealiumCategory(consent.name);

// when saving a Gdpr to tealium: map to a Dto
// documentation: https://docs.tealium.com/platforms/javascript/api/gdpr-functions/#utaggdprsetpreferencesvalues
const mapToConsentDto = (gdpr: Gdpr): TealiumConsentDto =>
  objectEntries(gdpr).reduce(
    (acc, [category, isConsented]) => ({
      ...acc,
      [TEALIUM_CATEGORIES_MAP[category]]: isConsented ? '1' : '0',
    }),
    {} as TealiumConsentDto
  );
