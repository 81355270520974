import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { State } from '@app/store';
import { Country, CountryResource } from '@mkp/gdpr/data-access';
import { Gdpr, selectGdpr, selectGdprIsDefined, selectGdprIsLoaded } from '@mkp/gdpr/state';
import { gdprModalActions } from '@mkp/gdpr/state/actions';
import { CookieService, isBot } from '@mkp/shared/util-device';
import { Store } from '@ngrx/store';
import { filter, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { GdprDialogContentComponent } from './gdpr-dialog-content/gdpr-dialog-content.component';
import { GdprFunctionalDialogContentComponent } from './gdpr-functional-dialog-content/gdpr-functional-dialog-content.component';

const BANNER_COOKIE_NAME = 'cookie_banner_closed';

type GdprModalCloseAction =
  | typeof gdprModalActions.initGdprFromModal
  | typeof gdprModalActions.setGdprFromUserFooter
  | typeof gdprModalActions.setGdprFromVisitorFooter
  | typeof gdprModalActions.setGdprFromZendeskPlaceholder;

export type GdprInitDisplay = 'banner' | 'modal';

interface GdprDialogOptions {
  action: GdprModalCloseAction;
  fullDetails: boolean;
}

@Injectable({ providedIn: 'root' })
export class GdprModalService {
  private readonly dialog = inject(MatDialog);
  private readonly store = inject(Store<State>);
  private readonly countryResource = inject(CountryResource);
  private readonly cookieService = inject(CookieService);

  private readonly gdpr$ = this.store.select(selectGdpr);

  /*
   * Define whether we should display a cookie banner, a cookie modal, or nothing
   * isBot -> show nothing (don't display anything for bots)
   * gdprIsDefined:
   *  - true:
   *     - if banner cookie is set: swiss user (show/hide banner depending on cookie value)
   *     - if no banner cookie is set: other country user (show nothing)
   *  - false: get country then show banner (for swiss) / modal (for others)
   */
  readonly gdprInitDisplay$ = this.store.select(selectGdprIsLoaded).pipe(
    filter(Boolean),
    take(1),
    switchMap(() => this.store.select(selectGdprIsDefined)),
    switchMap((gdprIsDefined: boolean) => {
      if (isBot()) {
        return of(false);
      }

      if (gdprIsDefined) {
        return this.wasBannerShown() && !this.wasBannerClosed() ? of('banner') : of(false);
      }

      return this.countryResource
        .fetchCountry()
        .pipe(map((country) => (country === Country.Switzerland ? 'banner' : 'modal')));
    })
  );

  openGdprDialog({ action, fullDetails }: GdprDialogOptions): Observable<Gdpr> {
    return this.gdpr$.pipe(
      take(1),
      switchMap((gdpr) => {
        const dialogRef = this.dialog.open(GdprDialogContentComponent, {
          panelClass: 'mkp-dialog-large',
          disableClose: true,
          data: {
            fullDetails,
            prefill: gdpr,
          },
        });

        return dialogRef.afterClosed();
      }),
      tap((gdpr) => {
        this.store.dispatch(action({ gdpr }));
      })
    );
  }

  openGdprFunctionalDialog(action: GdprModalCloseAction): Observable<Gdpr> {
    return this.gdpr$.pipe(
      take(1),
      switchMap((gdpr) => {
        const dialogRef = this.dialog.open(GdprFunctionalDialogContentComponent, {
          panelClass: 'mkp-dialog-large',
          disableClose: true,
        });

        return dialogRef
          .afterClosed()
          .pipe(
            map((consent: { functional: true } | undefined) => ({ ...gdpr, functional: !!consent }))
          );
      }),
      tap((gdpr) => {
        if (gdpr.functional) {
          this.store.dispatch(action({ gdpr }));
        }
      })
    );
  }

  saveShowBannerCookie(): void {
    this.cookieService.setCookieString(BANNER_COOKIE_NAME, 'false');
  }

  saveCloseBannerCookie(): void {
    this.cookieService.setCookieString(BANNER_COOKIE_NAME, 'true');
  }

  private wasBannerShown(): boolean {
    return this.cookieService.getCookieString(BANNER_COOKIE_NAME) != null;
  }

  private wasBannerClosed(): boolean {
    return this.cookieService.getCookieString(BANNER_COOKIE_NAME) === 'true';
  }
}
