import { createActionGroup, emptyProps, props } from '@ngrx/store';

// verification email
// This can be in settings reducer for now, but optimaly it would be in a separate auth reducer
export const onboardingActions = createActionGroup({
  source: 'Onboarding',
  events: {
    'Resend Email Verification Start': props<{ userId: string }>(),
    'Resend Email Verification Success': emptyProps(),
    'Resend Email Verification Failure': props<{ error: { message: string } }>(),
  },
});
