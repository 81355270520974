<ng-container *ngIf="products?.length; else skeleton">
  <mkp-product-card
    *ngFor="let product of products"
    [isLoading]="product.code === isProductLoading"
    [isReadMode]="isReadMode"
    [isCollapsed]="isCollapsed"
    [product]="product"
    [platforms]="platforms"
    [benefits]="benefits"
    [activePlatformGroup]="activePlatformGroup"
    [discountLineThrough]="discountLineThrough"
    (handleSelectedProduct)="handleSelectedProduct.emit($event)"
  />
</ng-container>

<ng-template #skeleton>
  <mkp-cards-skeleton />
</ng-template>
