import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { selectCompanies } from '@company/store/selectors';
import { AppConfig } from '@config/app.config';
import { SplashscreenService } from '@core/services';
import { selectAccounts } from '@mkp/account/state';
import { authActions, authApiActions } from '@mkp/auth/actions';
import { AuthFacade, Token } from '@mkp/auth/util';
import { selectIsNewAccountManagement } from '@mkp/debug/state';
import { verifyIdentityPendingParams } from '@mkp/onboarding/feature-verify-identity';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { selectIsBoUser, selectLoggedInUser } from '@user/store/selectors/user.selectors';
import { ZendeskService } from 'libs/zendesk/util/src';
import { combineLatest, from, of } from 'rxjs';
import { catchError, delay, filter, map, switchMap, take, tap } from 'rxjs/operators';

const ROUTS_WITHOUT_SELECTED_ACCOUNT = [AppConfig.routes.supportCenter];

export const appBootstrapped = createEffect(
  (
    actions$ = inject(Actions),
    authFacade = inject(AuthFacade),
    store = inject(Store),
    splashscreen = inject(SplashscreenService)
  ) =>
    actions$.pipe(
      ofType(authActions.appBootstrapped),
      switchMap(() =>
        authFacade.isAuthenticated$.pipe(
          switchMap((logged: boolean) => (logged ? of(logged) : authFacade.fetchAccessToken())),
          filter((logged: boolean | Token) => !!logged),
          switchMap(() => authFacade.isVerified$),
          concatLatestFrom(() => store.select(selectLoggedInUser).pipe(filter(Boolean))),
          map(([isVerified, user]) =>
            isVerified
              ? authActions.userIsVerified({ userId: user.id })
              : authActions.userIdentityNotVerified()
          ),
          catchError((error) => {
            splashscreen.hide();
            return of(authActions.appBootstrapFailed({ error }));
          })
        )
      )
    ),
  { functional: true }
);

export const userIdentityNotVerified = createEffect(
  (
    actions$ = inject(Actions),
    router = inject(Router),
    splashscreen = inject(SplashscreenService)
  ) =>
    actions$.pipe(
      ofType(authActions.userIdentityNotVerified),
      tap(() => {
        const navigated = router.navigate([AppConfig.routes.verifyIdentity], {
          queryParams: verifyIdentityPendingParams,
        });
        from(navigated).subscribe(() => splashscreen.hide());
      })
    ),
  { functional: true, dispatch: false }
);

export const userHasNoActiveMemberships = createEffect(
  (actions$ = inject(Actions), router = inject(Router), store = inject(Store)) =>
    actions$.pipe(
      ofType(authActions.loadActiveAccountMembershipsSuccess),
      filter(({ accountMemberships }) => accountMemberships.length === 0),
      concatLatestFrom(() => store.select(selectIsBoUser)),
      filter(([_, isBoUser]) => isBoUser !== undefined && !isBoUser),
      tap(() => router.navigate([AppConfig.routes.claimCompany]))
    ),
  { functional: true, dispatch: false }
);

export const userHasMultipleActiveMemberships = createEffect(
  (actions$ = inject(Actions), router = inject(Router), store = inject(Store)) =>
    actions$.pipe(
      ofType(authActions.loadActiveAccountMembershipsSuccess),
      concatLatestFrom(() => store.select(selectIsNewAccountManagement)),
      filter(
        ([{ accountMemberships }, isNewAccountManagement]) =>
          isNewAccountManagement && accountMemberships.length > 1
      ),
      filter(() => !ROUTS_WITHOUT_SELECTED_ACCOUNT.some((route) => router.url.includes(route))),
      tap(() => router.navigate([AppConfig.routes.selectAccountMembership]))
    ),
  { functional: true, dispatch: false }
);

// this is a temporary effect that needs to be removed when we
// remove the feature flag
export const userHasMultipleActiveMembershipsWithAccountManagementDisabled = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(authActions.loadActiveAccountMembershipsSuccess),
      filter(({ accountMemberships }) => accountMemberships.length > 1),
      switchMap(({ accountMemberships }) =>
        combineLatest([
          store.select(selectIsNewAccountManagement),
          store.select(selectAccounts).pipe(filter((accounts) => accounts.length > 0)),
          store.select(selectCompanies).pipe(filter((companies) => companies.length > 0)),
        ]).pipe(
          take(1),
          filter(([isNewAccountManagement]) => !isNewAccountManagement),
          map(([_, accounts, companies]) => {
            const accountMembershipId = accountMemberships[0].id;
            const accountId = accounts.find(
              (account) => account.id === accountMemberships[0].accountId
            )?.id as string;
            const companyProfileId = companies.find(
              (company) => company.legalEntityId === accountMemberships[0].account.legalEntityId
            )?.id as string;
            return authActions.workspaceSelected({
              accountMembershipId,
              accountId,
              companyProfileId,
            });
          })
        )
      )
    ),
  { functional: true }
);

export const workspaceSelected = createEffect(
  (actions$ = inject(Actions), router = inject(Router)) =>
    actions$.pipe(
      ofType(authActions.workspaceSelected),
      tap(() => router.navigate([AppConfig.routes.dashboard]))
    ),
  { functional: true, dispatch: false }
);

// cf JMP-4357
// when we fail to download the initial account we want to logout the user and display an error
// auth0 refreshes the page after a logout, so we need to set a flag in session to communicate that there was an error
export const forceLogoutWhenFailingToLoadInitialAccount = createEffect(
  (actions$ = inject(Actions), authFacade = inject(AuthFacade)) =>
    actions$.pipe(
      ofType(
        authActions.loadActiveAccountMembershipsFailed,
        authActions.loadAccountsAfterLoginFailed,
        authActions.loadCompaniesAfterLoginFailed
      ),
      tap(() => sessionStorage.setItem('forcedLogout', 'true')),
      tap(() => authFacade.logout())
    ),
  { functional: true, dispatch: false }
);

export const initialDataLoadingStarted = createEffect(
  (actions$ = inject(Actions), store = inject(Store), splashscreen = inject(SplashscreenService)) =>
    actions$.pipe(
      ofType(
        authActions.loadActiveAccountMembershipsSuccess,
        authActions.loadActiveAccountMembershipsFailed
      ),
      concatLatestFrom(() => store.select(selectLoggedInUser).pipe(filter(Boolean))),
      delay(500),
      tap(() => splashscreen.hide())
    ),
  { functional: true, dispatch: false }
);

export const updateZendeskToken = createEffect(
  (actions$ = inject(Actions), zendeskService = inject(ZendeskService)) =>
    actions$.pipe(
      ofType(authApiActions.fetchAccessTokenSuccess),
      tap(({ zendeskJWT }) => zendeskService.login(zendeskJWT))
    ),
  { functional: true, dispatch: false }
);
