import { Gdpr } from '@mkp/gdpr/state';
import { createActionGroup, props } from '@ngrx/store';

export const gdprModalActions = createActionGroup({
  source: 'Gdpr/Modal',
  events: {
    'Set Gdpr From Visitor Footer': props<{ gdpr: Gdpr }>(),
    'Set Gdpr From User Footer': props<{ gdpr: Gdpr }>(),
    'Set Gdpr From Zendesk Placeholder': props<{ gdpr: Gdpr }>(),
    'Init Gdpr From Banner': props<{ gdpr: Gdpr }>(),
    'Init Gdpr From Modal': props<{ gdpr: Gdpr }>(),
  },
});
