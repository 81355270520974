import { gdprTealiumActions } from '@mkp/gdpr/state/actions';
import { FetchState, LoadingState } from '@mkp/shared/util-state';
import { TrackingActions } from '@mkp/tracking/state/actions';
import { createFeature, createReducer, on } from '@ngrx/store';
import { Gdpr } from './gdpr.model';

export interface GdprState extends Gdpr {
  fetchState: FetchState;
}

export const initialState: GdprState = {
  functional: null,
  marketing: null,
  fetchState: LoadingState.INIT,
};

const reducer = createReducer(
  initialState,
  on(TrackingActions.loadTealiumSuccess, gdprTealiumActions.setGdprToTealiumSuccess, (state) => ({
    ...state,
    fetchState: LoadingState.LOADING,
  })),
  on(gdprTealiumActions.loadGdprFromTealiumSuccess, (state, { gdpr }) => ({
    ...state,
    functional: gdpr.functional,
    marketing: gdpr.marketing,
    fetchState: LoadingState.LOADED,
  })),
  on(
    gdprTealiumActions.loadGdprFromTealiumFailure,
    gdprTealiumActions.setGdprToTealiumFailure,
    (state, { error }) => ({
      ...state,
      fetchState: { error },
    })
  )
);

export const gdprFeature = createFeature({ name: 'gdprState', reducer });
