import { createAction, props } from '@ngrx/store';

export const fetchAccessTokenSuccess = createAction(
  '[Auth API] Fetch Access Token Success',
  props<{ actorId: string; zendeskJWT: string }>()
);
export const fetchAccessTokenFailure = createAction(
  '[Auth API] Fetch Access Token Failure',
  props<{ error: unknown }>()
);
