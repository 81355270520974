import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Gdpr } from '@mkp/gdpr/state';
import {
  Button,
  ButtonColor,
  ButtonComponent,
  ButtonSize,
  ButtonWrapper,
  FeedbackModule,
} from '@mkp/shared/ui-library';
import { TranslateModule } from '@ngx-translate/core';
import { GdprLinkPipe } from '../gdpr-link/gdpr-link.pipe';

const DEFAULT_BUTTON_CONFIG: Button = {
  mobileFullWidth: true,
  size: ButtonSize.Small,
};

@Component({
  templateUrl: 'gdpr-functional-dialog-content.component.html',
  styleUrl: '../gdpr-dialog-content/gdpr-dialog-content.component.scss',
  standalone: true,
  imports: [MatDialogModule, TranslateModule, GdprLinkPipe, ButtonComponent, FeedbackModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GdprFunctionalDialogContentComponent {
  readonly cancelBtnConfig: ButtonWrapper = {
    config: {
      ...DEFAULT_BUTTON_CONFIG,
      color: ButtonColor.Tertiary,
      disableRipple: true,
    },
    content: {
      label: 'GDPR.COOKIE-DIALOG.BUTTON.DISMISS',
    },
  };

  readonly gdprBtnConfig: ButtonWrapper = {
    config: {
      ...DEFAULT_BUTTON_CONFIG,
      color: ButtonColor.Primary,
    },
    content: {
      label: 'GDPR.MODAL.BUTTON.ACCEPT-FUNCTIONAL-COOKIES',
    },
  };

  constructor(
    private dialogRef: MatDialogRef<GdprFunctionalDialogContentComponent, Partial<Gdpr>>
  ) {}

  submit() {
    this.dialogRef.close({
      functional: true,
    });
  }

  dismiss() {
    this.dialogRef.close();
  }
}
