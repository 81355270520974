import { Injectable } from '@angular/core';

export interface Token {
  actorId: string;
  zendeskJWT: string;
  stringToken: string;
  expiryDate: Date;
}

enum JwtDomain {
  // it seems the token is always on the same domain, even on dev and stage
  mainDomain = 'https://jobcloud.ai',
}

interface MainData {
  zendeskJWT: string;
  marketplaceApiCore: {
    actorId: string;
    userId: string;
  };
}

type CoreBackEndMainData = {
  [key in JwtDomain]: MainData;
};

export interface CoreBackendTokenDto extends CoreBackEndMainData {
  iss: string;
  sub: string;
  aud: string[];
  iat: number;
  exp: number;
  azp: string;
  scope: string;
}

@Injectable({ providedIn: 'root' })
export class CoreBackendJwtService {
  getDataFromToken(token: string): Token {
    const { exp, [JwtDomain.mainDomain]: mainDomain } = this.parseToken(token.split('.')[1]);
    const { marketplaceApiCore, zendeskJWT } = mainDomain;

    return {
      actorId: marketplaceApiCore.actorId,
      stringToken: token,
      expiryDate: new Date(exp * 1000),
      zendeskJWT,
    };
  }

  // wrapper around JSON.parse and atob to display and swallow error
  private parseToken(stringValue: string): CoreBackendTokenDto {
    let object = {} as CoreBackendTokenDto;

    try {
      object = JSON.parse(atob(stringValue));
    } catch (error: unknown) {
      console.error(`CoreBackendJwtService - error when parsing token`, error);
    }

    return object;
  }
}
