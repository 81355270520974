import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { CustomSerializer } from '@app/store';
import { AuthService } from '@auth0/auth0-angular';
import { APP_CONFIG, AppConfig } from '@config/app.config';
import { HomeComponent } from '@mkp/core/feature-home';
import { NoNetworkComponent, VersionComponent } from '@mkp/core/ui';
import { SentryErrorHandler } from '@mkp/core/util-sentry';
import { GdprBannerComponent, GdprZendeskPlaceholderComponent } from '@mkp/gdpr/ui';
import { SnackbarModule } from '@mkp/shared/ui-library';
import { Actions } from '@ngrx/effects';
import { RouterStateSerializer } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { appInitFactory } from './app-init.factory';
import { CoreModule } from './core/core.module';
import { ProgressInterceptor, RequestInterceptor, TimingInterceptor } from './core/interceptors';
import { ProgressBarService } from './core/services';
import { RedirectService } from './services/redirect.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    CommonModule,
    VersionComponent,
    SnackbarModule,
    HomeComponent,
    NoNetworkComponent,
    GdprBannerComponent,
    GdprZendeskPlaceholderComponent,
  ],
  providers: [
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    { provide: Window, useValue: window },
    { provide: APP_CONFIG, useValue: AppConfig },
    { provide: LOCALE_ID, useValue: 'en-CH' },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitFactory,
      multi: true,
      deps: [Store, Actions, AuthService, Router, RedirectService],
    },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: TimingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProgressInterceptor,
      deps: [ProgressBarService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
