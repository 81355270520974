import { createActionGroup, props } from '@ngrx/store';

export const createVacancyWrapperActions = createActionGroup({
  source: 'Create Vacancy Wrapper',
  events: {
    'Active Step Changed': props<{
      stepIndex: number;
      nbSuggestions: number;
      vacancyTemporaryId: string;
      vacancyId: string;
    }>(),
  },
});
