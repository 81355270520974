import { createActionGroup, props } from '@ngrx/store';

export const VacancyEditActions = createActionGroup({
  source: 'Vacancy Edit',
  events: {
    'Suggest Button Clicked': props<{
      vacancyId: string | undefined;
      vacancyTemporaryId?: string;
    }>(),
    'Cancel Suggest Button Clicked': props<{
      vacancyId: string | undefined;
      vacancyTemporaryId?: string;
    }>(),
  },
});
