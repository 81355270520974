import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { selectGdprFunctional, selectGdprIsLoaded } from '@mkp/gdpr/state';
import { gdprModalActions } from '@mkp/gdpr/state/actions';
import { GdprModalService } from '@mkp/gdpr/ui';
import { LanguageIso } from '@mkp/shared/data-access';
import { Store } from '@ngrx/store';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { WindowWithZendesk } from './zendesk.model'; /*
check docs on https://jobcloud.atlassian.net/wiki/spaces/CORE/pages/84849623279/Zendesk+Implementation
*/

@Injectable({
  providedIn: 'root',
})
export class ZendeskService {
  private readonly window = inject(Window) as WindowWithZendesk;
  private readonly store = inject(Store);
  private readonly destroy = inject(DestroyRef);
  private readonly gdprModalService = inject(GdprModalService);
  private isInitialized$ = new BehaviorSubject<boolean>(false);
  private isInitializing = false;

  constructor() {
    this.handleConsentChanges();
  }

  private handleConsentChanges() {
    this.store
      .select(selectGdprIsLoaded)
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(() => this.store.select(selectGdprFunctional)),
        takeUntilDestroyed(this.destroy)
      )
      .subscribe((functional) => {
        if (functional) {
          this.initializeZendeskIfNeeded()
            .pipe(tap(() => this.enableCookies()))
            .subscribe();
        } else {
          this.disableCookies();
        }
      });
  }
  private initializeZendeskIfNeeded(): Observable<boolean> {
    return this.isInitialized$.pipe(
      take(1),
      switchMap((isInitialized) => {
        if (isInitialized) {
          return of(true);
        } else if (this.isInitializing) {
          return this.isInitialized$.pipe(filter(Boolean), take(1));
        } else {
          this.isInitializing = true;
          return from(
            new Promise<boolean>((resolve) => {
              if (this.window.zE) {
                this.isInitialized$.next(true);
                this.isInitializing = false;
                resolve(true);
              } else if (typeof (this.window as any).initZendesk === 'function') {
                (this.window as any).initZendesk();
                const checkZendesk = setInterval(() => {
                  if (this.window.zE) {
                    clearInterval(checkZendesk);
                    this.isInitialized$.next(true);
                    this.isInitializing = false;
                    resolve(true);
                  }
                }, 100);
              } else {
                console.error('Zendesk initialization function not found');
                this.isInitializing = false;
                resolve(false);
              }
            })
          ).pipe(
            catchError((error) => {
              console.error('Failed to initialize Zendesk:', error);
              this.isInitializing = false;
              return of(false);
            })
          );
        }
      })
    );
  }

  openWidget(): void {
    this.store
      .select(selectGdprFunctional)
      .pipe(
        take(1),
        switchMap((functional) => {
          if (functional) {
            return this.initializeZendeskIfNeeded().pipe(
              tap(() => this.getZendesk()('messenger', 'open'))
            );
          } else {
            return this.gdprModalService
              .openGdprFunctionalDialog(gdprModalActions.setGdprFromZendeskPlaceholder)
              .pipe(
                switchMap((result) => {
                  if (result && result.functional) {
                    return this.store.select(selectGdprFunctional).pipe(
                      filter(Boolean),
                      take(1),
                      switchMap(() => this.initializeZendeskIfNeeded()),
                      tap(() => this.getZendesk()('messenger', 'open'))
                    );
                  }
                  return of(false);
                })
              );
          }
        })
      )
      .subscribe();
  }

  private getZendesk() {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return this.window.zE || (() => {});
  }

  private enableCookies() {
    this.getZendesk()('messenger:set', 'cookies', true);
  }

  private disableCookies() {
    this.getZendesk()('messenger:set', 'cookies', false);
  }

  closeWidget() {
    this.getZendesk()('messenger', 'close');
  }

  // see locales https://support.zendesk.com/api/v2/locales/public.json
  setLocale(locale: LanguageIso) {
    this.getZendesk()('messenger:set', 'locale', mapLanguageIsoToZendeskLocale(locale));
  }

  login(zendeskJWT: string) {
    this.getZendesk()('messenger', 'loginUser', function (callback: any) {
      callback(zendeskJWT);
    });
  }
}

function mapLanguageIsoToZendeskLocale(languageIso: LanguageIso) {
  switch (languageIso) {
    case LanguageIso.GERMAN:
      return 'de';
    case LanguageIso.FRENCH:
      return 'fr';
    case LanguageIso.ENGLISH:
      return 'en-US';
    default:
      return 'en-US';
  }
}
