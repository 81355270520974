import {
  BoUserNavigation,
  MultiUserNavigation,
  RegularUserNavigation,
} from '@config/navigation.config';
import { createSelector } from '@ngrx/store';
import { fromUser } from '@user/store/reducers';
import { UserDisplayType, UserRole, UserType } from '@mkp/user/data-access';
import { selectIsLegalEntityBeingVerified } from '../../../../../../../../libs/account/state/src/lib/account/account.selectors';

export const {
  selectLoading,
  selectLoaded,
  selectTotalCount,
  selectLinks,
  selectFilter,
  selectEntities: selectUsers,
  selectFeatureState,
  selectById: selectUserById,
} = fromUser.selectors;

export const selectLoggedInUser = createSelector(
  selectFeatureState,
  selectUsers,
  ({ loggedInId }, users) => (loggedInId ? users[loggedInId] : null)
);

export const selectLoggedInUserEmail = createSelector(selectLoggedInUser, (user) =>
  user ? user.email : null
);

export const selectLoggedInUserId = createSelector(selectLoggedInUser, (user) => user?.id);

export const selectIsBoUser = createSelector(
  selectLoggedInUser,
  (user) =>
    user?.settings?.displayType === UserDisplayType.CustomerService ||
    user?.role === UserRole.SuperAdmin //  fix(CDA-925): temp fix for admin users
);
export const selectUserDisplayType = createSelector(
  selectLoggedInUser,
  (user) => user?.settings?.displayType
);

export const selectUserPublicationAiUsageConsent = createSelector(
  selectLoggedInUser,
  (user) => user?.settings?.publicationAiUsageConsent
);

export const selectList = createSelector(selectFeatureState, selectUsers, ({ list }, users) =>
  list.map((userId) => users[userId])
);

export const selectUserType = createSelector(
  selectIsBoUser,
  (isBoUser): UserType => (isBoUser ? UserType.BoUser : UserType.RegularUser)
);

function getNavigationLinksByUserType(userType: UserType) {
  switch (userType) {
    case UserType.RegularUser:
      return RegularUserNavigation;
    case UserType.BoUser:
      return BoUserNavigation;
    case UserType.MultiUser:
      return MultiUserNavigation;
    default:
      return RegularUserNavigation;
  }
}

const selectNavigationLinksByUserType = createSelector(
  selectUserType,
  getNavigationLinksByUserType
);

export const selectIsSelectedAccountBeingVerified = createSelector(
  selectIsBoUser,
  selectIsLegalEntityBeingVerified,
  // never display "account is being verified" features for multi-account users
  (isMultipleAccountsUser, isLegalEntityBeingVerified) =>
    !isMultipleAccountsUser && isLegalEntityBeingVerified
);
