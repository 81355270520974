import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ZendeskService } from 'libs/zendesk/util/src';
@Component({
  selector: 'ui-gdpr-zendesk-placeholder',
  templateUrl: './gdpr-zendesk-placeholder.template.html',
  styleUrl: './gdpr-zendesk-placeholder.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GdprZendeskPlaceholderComponent {
  private readonly zendeskService = inject(ZendeskService);

  openModal() {
    this.zendeskService.openWidget();
  }
}
