import { ApplicationStepWithoutDeclined } from '@mkp/application/data-access';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const applicationPageActions = createActionGroup({
  source: 'Applications Page',
  events: {
    Opened: props<{ step: ApplicationStepWithoutDeclined; limit: number; offset: 0 }>(),
    'Load More Applications': props<{
      step: ApplicationStepWithoutDeclined;
      limit: number;
      offset: number;
    }>(),
    'Load One More Application': props<{
      step: ApplicationStepWithoutDeclined;
      limit: number;
      offset: number;
    }>(),
    'Load More Applications To Find Route Application': props<{
      step: ApplicationStepWithoutDeclined;
      limit: number;
      offset: number;
    }>(),
    'Application Delete': props<{ applicationId: string; applicationFullName: string }>(),
    'Tab Changed': props<{ step: ApplicationStepWithoutDeclined; limit: number; offset: 0 }>(),
    'User Clicked On Status In Dropdown': props<{
      applicationId: string;
      statusId: string;
      _version: string;
    }>(),
    'User Clicked On Status In Action Button': props<{
      applicationId: string;
      statusId: string;
      _version: string;
    }>(),
    'View Document': props<{
      pdfUrl: string;
      documentId: string;
      applicationId: string;
      originalUrl: string;
    }>(),
    'User Select Application': props<{ applicationId: string }>(),
    'Select Application On Change': props<{ applicationId: string | undefined }>(),
    'Select First Application': props<{ applicationId: string | undefined }>(),
    'Select Route Application': props<{ applicationId: string }>(),
    'Download Document': props<{
      downloadUrl: string;
      documentId: string;
      applicationId: string;
      documentName: string;
    }>(),
    'Application Refresh Status Changed': emptyProps(),
  },
});
