import { Injectable } from '@angular/core';
import { MinMax } from '@app/config/metadatas/models';
import { CompanyProfile } from '@company/models/company-profile.model';
import { EMPLOYEE_COUNT_RANGE } from '@config/metadatas/constants';

@Injectable({ providedIn: 'root' })
export class CompanyProfileSerializer {
  fromJson(json: CompanyProfile): CompanyProfile {
    const { employeeCountFrom, employeeCountTo } = mapEmployeeCount(
      json.employeeCountFrom,
      json.employeeCountTo
    );

    return {
      id: json.id,
      name: json.name,
      links: json.links,
      benefits: json.benefits,
      description: json.description,
      legalEntityId: json.legalEntityId,
      legalEntityName: json.legalEntityName,
      legalEntityAddress: json.legalEntityAddress,
      legalEntityState: json.legalEntityState,
      legalEntityVersion: json.legalEntityVersion,
      logoMediaApiId: json.logoMediaApiId,
      headerMediaApiId: json.headerMediaApiId,
      employeeCountFrom: employeeCountFrom,
      employeeCountTo: employeeCountTo,
      companySize: `${employeeCountFrom}-${employeeCountTo}`,
      _embedded: json._embedded,
      locations: json.locations,
      videos: json.videos?.map(({ __typename, ...rest }) => rest) ?? [],
      _version: json._version,
      _links: json._links,
    };
  }
}

const mapEmployeeCount = (
  minInput: number,
  maxInput: number
): Pick<CompanyProfile, 'employeeCountFrom' | 'employeeCountTo'> => {
  const { min: closestMin, max: backupMax } = getClosestRange(minInput);
  const { max: closestMax } = getClosestRange(maxInput);

  return {
    employeeCountFrom: closestMin,
    // use backupMax if max is somehow lower than min
    employeeCountTo: closestMax > closestMin ? closestMax : backupMax,
  };
};

const getClosestRange = (value: number): MinMax =>
  EMPLOYEE_COUNT_RANGE.find(({ max }) => value <= max) ?? EMPLOYEE_COUNT_RANGE[0];
