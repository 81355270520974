import { LegalEntity } from '@mkp/account/data-access';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ClaimCompanyActions = createActionGroup({
  source: 'Claim Company',
  events: {
    'Contact Customer Support': emptyProps(),
    'Company Claim Slack Notification': props<{ email: string; company: string | LegalEntity }>(),
  },
});
