import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProductDisplayButtonSwitchOfferingsComponent } from '@mkp/product/feature-product-display';
import { ProductTableComponent } from '@mkp/product/feature-product-table';
import { LetDirective } from '@ngrx/component';
import { AbstractProductDisplayDirective } from '../abstract-product-display/abstract-product-display.directive';
import { ProductDisplayCardListComponent } from '../product-display-card-list/product-display-card-list.component';

@Component({
  selector: 'mkp-product-display-read-only',
  standalone: true,
  imports: [
    LetDirective,
    NgTemplateOutlet,
    ProductDisplayButtonSwitchOfferingsComponent,
    ProductTableComponent,
    ProductDisplayCardListComponent,
  ],
  templateUrl: './product-display-read-only.component.html',
  styleUrl: './product-display-read-only.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductDisplayReadOnlyComponent extends AbstractProductDisplayDirective {
  @Input() showPlatformSwitch = false;
  @Input() showStickyHeader = false;
  @Input() productAlternativeUI = false;
  @Input() discountLineThrough = false;
}
