<ng-container
  *ngrxLet="{
    displayedProducts: displayedProducts$,
    activePlatformGroup: activePlatformGroup$,
    platforms: platforms$,
    benefits: benefits$,
    switchedProducts: switchedProducts$,
    products: products$,
    isMobile: isMobile$
  } as vm"
>
  @if (showPlatformSwitch && vm.displayedProducts) {
    <mkp-product-display-button-switch-offerings
      (handleSwitchOffering)="handleSwitchPlatformGroup($event)"
      [activePlatformGroup]="vm.activePlatformGroup!"
    />
  }

  <ng-container [ngTemplateOutlet]="vm.isMobile ? mobile : desktop" />

  <ng-template #desktop>
    <mkp-product-table
      [isProductLoading]="false"
      [platforms]="vm.platforms"
      [benefits]="vm.benefits"
      [products]="vm.displayedProducts"
      [isReadMode]="true"
      [hideStickyHeader]="!showStickyHeader"
      [productAlternativeUI]="productAlternativeUI"
      [discountLineThrough]="discountLineThrough"
      [activePlatformGroup]="vm.activePlatformGroup!"
      (productScrolled)="handleProductsScroll($event)"
    />
  </ng-template>

  <ng-template #mobile>
    <mkp-product-display-card-list
      [isProductLoading]="false"
      [products]="vm.displayedProducts!"
      [benefits]="vm.benefits"
      [platforms]="vm.platforms"
      [isReadMode]="true"
      [discountLineThrough]="discountLineThrough"
      [activePlatformGroup]="vm.activePlatformGroup!"
    />
  </ng-template>
</ng-container>
